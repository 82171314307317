$spacing-3xs: 2px;
$spacing-2xs: 4px;
$spacing-xs: 8px;
$spacing-sm: 12px;
$spacing-md: 16px;
$spacing-lg: 20px;
$spacing-xl: 24px;
$spacing-2xl: 28px;
$spacing-3xl: 32px;
$spacing-4xl: 40px;
$spacing-5xl: 48px;
$spacing-6xl: 50px;
