$red: #f03a47;
$light-blue: #2ebbec;
$light-blue-100: #a5ddff;
$light-blue-200: #31b8eb;
$dark-blue: #345995;
$dark-blue-100: #004963;
$yellow: #fec00b;
$orange: #ef8e00;
$dark-orange: #fe7762;
$green: #51cb20;
$light-green-300: #82d873;
$dark-grey-500: #475569;
$dark-grey-400: #44444f;
$dark-grey-100: #92929d;
$dark-grey-300: #979898;
$dark-grey-200: #afafaf;
$grey: #94a3b8;
$light-grey-1000: #d9d9d9;
$light-grey-900: #c4c4c4;
$light-grey-800: #e6e6e7;
$light-grey-700: #e0eafb;
$light-grey-600: #f6f8fb;
$light-grey-500: #cbd5e1;
$light-grey-400: #cac9c9;
$light-grey-300: #fafafb;
$light-grey-250: #f6f6f6;
$light-grey-200: #f2f4f6;
$light-grey-100: #fafafa;
$impure-grey: #828282;
$white: #ffffff;
$shadow: #0000000f;
$black-200: #333;
$black-100: #212526;
$black: #0a3645;
$impure-black: #2d2d2d;
