@use '../../assets/styles/index' as *;

.top-bar {
  height: 12vh;
  width: 100%;
  background-image: url('../../assets/images/webp/topBar.webp');
  background-size: 100% 100%;

  .top-left {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: $spacing-3xl;
    padding-bottom: $spacing-xs;
    justify-content: flex-end;

    .heading {
      font-size: $font-xs + 0.2rem;
      color: white;
    }
    .sub-heading {
      color: white;
      font-weight: 600;
      font-size: $font-lg + 0.8rem;
    }
  }
}
.extend {
  background-image: url('../../assets/images/webp/dashboardBar.webp');
  // on the dashboard the size of the top bar is extended and so we increase its height and give it a z index of 4
  height: 25vh;
  z-index: 4;
  position: absolute;
  max-height: 400px;

  .top-left {
    justify-content: center;
  }
}

.hide {
  display: none;
}
