// for the most common breakpoints
$bp-xl: 'screen and (max-width: 1680px)';

$bp-lg: 'screen and (max-width: 1450px)';

$bp-md: 'screen and (max-width: 1250px)';

$bp-sm: 'screen and (max-width: 800px)';

$bp-xs: 'screen and (max-width: 500px)';

// for other breakpoints
@mixin media-width($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin media-height($height) {
  @media screen and (max-height: $height) {
    @content;
  }
}
