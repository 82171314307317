@use '../../assets/styles/index' as *;

.sidenav {
  height: 100%;
  width: 300px;
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  background-color: $light-grey-100;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;

  @media #{$bp-xs} {
    width: 280px;
  }
}

@media (min-width: 800px) {
  .ham-icon {
    display: none;
  }

  .sidenav {
    display: none;
  }
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.side-nav-options {
  background-color: $white;
  border-radius: 12px;
  z-index: 43;
  padding: $spacing-md;
  margin: $spacing-xs $spacing-sm;
  cursor: pointer;
  border: 2px solid $light-grey-200;
  @media #{$bp-lg} {
    padding: $spacing-sm;
  }
  @media #{$bp-md} {
    padding: $spacing-xs;
    margin: $spacing-2xs $spacing-xs;
  }

  .icon {
    height: 35px;
    width: 35px;
    margin: $spacing-2xs;

    @media #{$bp-lg} {
      height: 30px;
      width: 30px;
    }
    @media #{$bp-md} {
      height: 25px;
      width: 25px;
    }
    @media #{$bp-sm} {
      height: 20px;
      width: 20px;
    }
  }

  .side-nav-heading {
    font-weight: bold;
    font-size: $font-sm;
    margin: $spacing-xs 0px;
    color: $black;
  }

  .side-bar-text {
    font-size: $font-xs + 0.2rem;
    color: $dark-grey-500;
  }
  &:before {
    content: '';
    display: block;
    padding-left: 100%;
  }
}

.builder-selected {
  border: 2px solid $light-blue;
}

.side-bar {
  min-width: 110px;
  max-width: 170px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  // calculates the height of a component so that it fits the screen, The screen has a 70px high header plus a 12 vh high top bar thus the height of all components below this is 100vh-12vh-70px
  height: calc(88vh - 70px);
  background-color: $light-grey-100;
}
@media (max-width: 600px) {
  .side-bar {
    display: none;
  }
}
.for-clients {
  height: calc(100vh - 70px);
}

.hide {
  display: none;
}
