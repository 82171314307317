@import url('https://fonts.googleapis.com/css2?family=Inter&family=Manrope:wght@300&family=Poppins&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0;
  height: 100%;
}

a {
  text-decoration: none;
  color: black;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  min-height: 100%;
}
