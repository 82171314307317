@use '../../assets/styles/index' as *;

.header {
  height: 70px;
  width: 100%;
  display: flex;
  padding: 0px $spacing-lg;
  justify-content: flex-start;
  align-items: center;
  z-index: 2;
  background-color: $white;

  .logo-container {
    height: 100%;
    width: 200px;
    padding: $spacing-xs;
    margin-right: $spacing-3xl + $spacing-md;
    margin-left: $spacing-lg;
  }

  @include media-width(800px) {
    .logo-container {
      margin-right: 0px;
    }
  }

  @include media-width(500px) {
    .logo-container {
      width: 80%;
      margin-left: $spacing-2xs;
      margin-right: $spacing-lg;
      padding: 0px;
    }
  }

  .options {
    min-width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: flex-start;

    .option {
      padding: $spacing-xs $spacing-md;
      cursor: pointer;
      display: flex;
      gap: $spacing-xs;

      font-style: normal;
      font-weight: bold;
      font-size: $font-sm + 0.2rem;
      line-height: 21px;
      color: rgba(33, 37, 38, 0.6);

      .new-client-added {
        background-color: $light-blue;
        border-radius: $spacing-sm;
        height: 10px;
        width: 10px;
        margin-top: 6px;
      }

      .chat-count-badge {
        background-color: $light-blue;
        color: $white;
        padding: 0px $spacing-xs;
        border-radius: $spacing-sm;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 25px;
        font-size: $font-xs + 0.2rem;
      }
    }
    .selected {
      color: $light-blue;
    }
  }

  @media (max-width: 1080px) {
    .options {
      display: none !important;
    }
  }

  .login-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;

    .profile-icon {
      display: flex;
      align-items: center;

      .image {
        height: 50px;
        width: 50px;
        border-radius: 25px;
      }
      .user-options {
        display: flex;
        position: relative;
        align-self: flex-end;
        align-items: flex-end;
        flex-direction: column;
        align-content: flex-end;
        margin: auto 10px;
        z-index: 5;

        .drop-down {
          top: 37px;
          right: 10px;
          width: 225px;
          border-radius: 4px;
          background: $white;
          position: absolute;
          border: 0.5px solid $light-grey-500;
          box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.05);
          padding: 10px $spacing-sm;

          .close-btn {
            width: 100%;
            margin: auto;
            display: flex;
            justify-content: flex-end;
          }
          .heading {
            font-size: $font-sm + 0.2rem;
            border-bottom: 2px solid $light-grey-500;
          }
          .option {
            display: flex;
            align-items: center;
            font-size: $font-sm;
            padding: $spacing-xs $spacing-md;
            cursor: pointer;
          }
        }
      }
      @include media-width(500px) {
        .image {
          height: 32px;
          width: 32px;
        }
        .user-options {
          margin-inline: 0px;
        }
      }
    }
    @include media-width(500px) {
      .profile-icon {
        margin-right: 8px;
      }
    }

    .bell-icon {
      display: flex;
      align-items: center;
      align-self: center;
      justify-self: center;
      margin-left: auto;
      padding-right: 20px;
      min-width: 50px;
      .chat-count-badge {
        position: relative;
        right: 8px;
        bottom: 4px;
        background-color: $light-blue;
        color: $white;
        padding: 0px $spacing-xs;
        border-radius: $spacing-sm;
        font-size: $font-xs + 0.2rem;
      }
    }
    @include media-width(500px) {
      .bell-icon {
        min-width: 0px;
        padding-right: 0px;
      }
    }
    .notifications {
      z-index: 5;
      width: 350px;
      max-height: 500px;
      overflow: auto;
      padding: 10px;
      opacity: 1;
      position: absolute;
      top: 60px;
      right: 50px;
      border-radius: 5px 0px 5px 5px;
      background-color: #fff;
      box-shadow:
        0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      display: flex;
      flex-direction: column;
      font-size: $font-xs;
      @media (max-width: 600px) {
        .notifications {
          width: 200px;
        }
      }
      .close-btn {
        width: 100%;
        margin: auto;
        display: flex;
        justify-content: flex-end;
      }
      .heading {
        font-size: $font-sm + 0.2rem;
        border-bottom: 2px solid $light-grey-500;
      }
      .all-notifications {
        margin-top: $spacing-sm;
        overflow: auto;
        .notification {
          display: flex;
          align-items: center;
          padding: 16px;
          .notification-icon {
            flex: 1;
            margin-right: $spacing-xs;
            width: 30px;
          }
          .text-date {
            flex: 8;
            .notification-text {
              font-size: $font-xs + 0.2rem;
              margin-bottom: $spacing-xs;
            }
          }
        }
        .unread {
          background-color: #ccf2ff;
        }
        .line-break {
          height: 2px;
          background-color: $light-grey-200;
          width: 100%;
        }
      }
      .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 2rem;
        margin-left: 50px;
      }
      .text {
        font-size: $font-xs + 0.2rem;
        font-weight: normal;
      }
    }
  }
  .logout-button {
    background-color: transparent;
    border: none;
  }
}
